// ** React Imports
import { ReactNode, useEffect, useMemo } from 'react'

// ** Next Imports
// ** react hook form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// ** Shared utils
// ** i18n imports
import { useTranslation } from 'react-i18next'

// ** MUI Components
import { Box, CircularProgress, Divider, FormHelperText } from '@mui/material'
import {
  BoxWrapper,
  ForgotPasswordText,
  FormCaptcha,
  FormWrapper,
  GoogleSignInButton,
  Heading,
  LoginForm,
  loginFormSchema,
  Meta,
  NavLink,
  SignUpText,
  SignUpWrapper,
  SubmitButton
} from '@/shared/ui'
import { FormInput, FormPassword } from '@/shared/ui/react-hook-form'
import { useVerified } from '@/shared/hooks/useVerified'
import { useAuth } from '@/shared/hooks/useAuth'
import { UserLayout } from '@/shared/layouts'
import { useRouter } from 'next/router'

/**
 * default login form values
 */
const defaultValues = {
  email: '',
  password: '',
  'g-recaptcha-response': ''
}

/**
 * interface describes types for login form values
 */
export interface LoginFormData {
  email: string
  password: string
  'g-recaptcha-response': string
}

const LoginPage = () => {
  // ** Hooks
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const deletedKey = router?.query?.is_deleted
  const isDeletedAccount = deletedKey === '1'

  useEffect(() => {
    isDeletedAccount && router.push('/you-account-deleted')
  }, [isDeletedAccount, router])

  /**
   * react hook form
   */
  const schema = useMemo(() => loginFormSchema(), [i18n.language])

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, isSubmitting }
  } = useForm<LoginFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    criteriaMode: 'all'
  })

  const { token, captchaRef, verifiedCaptcha, onCaptchaChange } = useVerified(false, clearErrors)

  /**
   * vars and handlers
   */
  const hidden = useMediaQuery(theme.breakpoints.down('md'))
  const hasErrors = Object.keys(errors).length > 0

  const onSubmit = async (data: LoginFormData) => {
    await auth.login(
      {
        email: data.email,
        password: data.password,
        'g-recaptcha-response': String(token)
      },
      (e: any) => {
        const status = e?.response?.status

        if (status === 403) {
          router.replace('/email-not-verified')
        }
        if (status === 404) {
          router.replace('/contact-support')
        }
      },
      setError
    )
  }

  return (
    <Box className='content-center'>
      <Meta title={'Crypto processing - Login'} />
      <FormWrapper>
        <Box>
          <Heading text={t('Authorization')} />
        </Box>
        <BoxWrapper>
          <LoginForm noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              name='email'
              control={control}
              label={t('Email')}
              placeholder={t('Enter your email')}
              inputProps={{ minLength: 8, maxLength: 100 }}
            />
            <FormPassword
              name='password'
              wrapperStyle={{ height: '77px' }}
              control={control}
              label={t('Password')}
              placeholder={t('Enter your password')}
              inputProps={{ minLength: 8, maxLength: 16 }}
            />
            <Box>
              <FormCaptcha
                control={control}
                name='g-recaptcha-response'
                captchaRef={captchaRef}
                onChange={(token: string | null) => onCaptchaChange(token)}
              />
            </Box>
            <SubmitButton
              fullWidth
              size='large'
              disabled={!isDirty || !isValid || !verifiedCaptcha || isSubmitting}
              type='submit'
              variant='contained'
            >
              {isSubmitting ? <CircularProgress color='inherit' size={30} /> : t('Login')}
            </SubmitButton>
            <Box sx={{ mt: '24px' }}>
              {hasErrors && (
                <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '5px' }}>
                  {t('Incorrect login or password. Please check them and try again')}
                </FormHelperText>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: '24px' }}>
              <ForgotPasswordText variant='subtitle1'>
                {`${t('Forgot password')}?`}
                <NavLink href='/forgot-password' linkText={t('Reset password')} />
              </ForgotPasswordText>
            </Box>
          </LoginForm>
          {!hidden ? <Divider orientation='vertical' color='#292929' sx={{ height: '556px' }} /> : null}
          <SignUpWrapper hidden={hidden}>
            <SignUpText variant='subtitle1'>
              {`${t('New user')}?`}
              <NavLink href='/register' linkText={t('Sign Up')} />
            </SignUpText>
            <SignUpText>{t('or')}</SignUpText>
            <GoogleSignInButton />
          </SignUpWrapper>
        </BoxWrapper>
      </FormWrapper>
    </Box>
  )
}

LoginPage.getLayout = (page: ReactNode) => <UserLayout visibleHeader>{page} </UserLayout>

LoginPage.guestGuard = true

export default LoginPage
